import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../../components/seo'
import Container from '../../components/Common/Container'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import DashboardModuleBlock from '../../components/DashboardModuleBlock'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import { DescriptionBlock2 } from '../../components/description-block'
import { PreviewBlock2 } from '../../components/preview-block'
import RequestDemo from '../../components/RequestDemo'
import ContactForm, { IContactModalState } from '../../components/contact-form'
import ModalDownloadApp from '../../components/Common/ModalDownloadApp'
import { Button } from '../../components/Common/Button'
import VideoSection from '../../components/video-section'
import ModalDownloadFile from '../../components/Common/ModalDownloadFile'

import { AppRegionEnum } from '../../types/app-region.enum'
import { LocalesEnum } from '../../types/locales.enum'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import {
  downloadPriceListLink,
  inspAndroidLinkAe,
  inspIOSLinkAe,
  signalMainDownloadPresentationLink,
} from '../../data'
import { isBrowser, toLink } from '../../utils'

import signaxPreviewVideo from '../../assets/video/signax-inspection-preview.mp4'
import signalPreviewVideo from '../../assets/video/signal-inspection-preview.mp4'
import Photo360App from '../../svg/photo360-app'
import PhotoAgentApp from '../../svg/photo-agent-app'
import SgnlImageSeo from '../../assets/images/seo/sgnl/inspection.png'
import SgnxImageSeo from '../../assets/images/seo/sgnx/inspection.png'

import * as s from './Inspection.module.scss'
import ModuleIconInspection from '../../svg/module-icon-inspection'

const InspectionPage: React.FC = () => {
  const { t, locale } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const { region, location } = React.useContext(GlobalContext)
  const [modal, setModal] = useState<IContactModalState>()
  const [appModal, setAppModal] = useState(false)
  const [presentationModal, setPresentationModal] = React.useState(false)
  const locationState = location?.state

  useEffect(() => {
    if (isBrowser()) {
      if (locationState?.presentationModalOpened) {
        setPresentationModal(true)
        locationState.presentationModalOpened = false
      }
    }
  }, [locationState, t])

  return (
    <>
      <SEO
        title={t('seo_title_inspection')}
        description={t('seo_description_inspection')}
        image={region === AppRegionEnum.Ru ? SgnlImageSeo : SgnxImageSeo}
        keywords={
          region === AppRegionEnum.Us ? t('keywords_inspection') : undefined
        }
      />
      <BannerSection
        imageDesktop={
          images[`banner_${region}`].childImageSharp.gatsbyImageData
        }
        imageMobile={
          images[`banner_mobile_${region}`].childImageSharp.gatsbyImageData
        }
        title={`${t('Signal')} Inspection`}
        moduleIcon={<ModuleIconInspection className={s.logo} />}
        colorShadow="dark"
      />
      <Section>
        <DescriptionBlock2
          title={t('Inspection title')}
          highlightTitleWordsByIdx={
            locale === LocalesEnum.Ru
              ? [2]
              : locale === LocalesEnum.Us
              ? [3, 4, 5]
              : [0, 1, 2, 3, 4]
          }
        >
          <div className={s.photo_agent_wrap}>
            <div className={s.photo_agent} onClick={() => setAppModal(true)}>
              <div className={s.photo_agent_icon}>
                <PhotoAgentApp />
              </div>
              <p className={s.photo_agent_text}>
                {t('Photo agent app for taking Photos 360°')}
              </p>
            </div>
            <div
              className={s.photo_agent}
              onClick={() =>
                region === AppRegionEnum.Ru
                  ? toLink('https://pa.sgnl.pro/public/album/bq7ga')
                  : toLink('https://pa.signax.io/public/album/mbqga')
              }
            >
              <div className={s.photo_agent_icon}>
                <Photo360App />
              </div>
              <p className={s.photo_agent_text}>
                {t('Check Photos 360° from any device')}
              </p>
            </div>
          </div>
        </DescriptionBlock2>
        <PreviewBlock2
          src={
            region === AppRegionEnum.Ru
              ? signalPreviewVideo
              : signaxPreviewVideo
          }
          srcTitle={t('_insp_image_title')}
        />
      </Section>
      <ModuleSection2
        title={
          region === AppRegionEnum.Ru
            ? t('Module features')
            : t('Module advantages')
        }
      >
        <ModuleSectionItem2
          title={t('_insp_subtitle')}
          description={t('_insp_lead')}
          quote={{
            author: 'author_16',
            position: t('Senior Project Engineer'),
            text: t('_insp_quote'),
          }}
          image={images[`image1_${region}`].childImageSharp.gatsbyImageData}
        />
        <ModuleSectionItem2
          title={t('_insp_subtitle2')}
          positionTitle="right"
          description={t('_insp_lead2')}
          quote={{
            author: 'Sergey Kazantsev',
            position: t('Project manager'),
            text: t('_insp_quote2'),
          }}
          image={images[`image2_${region}`].childImageSharp.gatsbyImageData}
          reverseElements
        />
        <ModuleSectionItem2
          title={t('_insp_subtitle3')}
          description={t('_insp_lead3')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_23' : 'Michel K.',
            position: t('Safety Manager'),
            text: t('_insp_quote3'),
          }}
          image={images[`image3_${region}`].childImageSharp.gatsbyImageData}
        />
        <ModuleSectionItem2
          title={t('_insp_subtitle4')}
          positionTitle="right"
          description={t('_insp_lead4')}
          quote={{
            author: 'author_16',
            position: t('Senior Project Engineer'),
            text: t('_insp_quote4'),
          }}
          image={images[`image4_${region}`].childImageSharp.gatsbyImageData}
          reverseElements
        />
        <ModuleSectionItem2
          title={t('_insp_subtitle5')}
          description={t('_insp_lead5')}
          quote={{
            author: 'Sergey Kazantsev',
            position: t('Project manager'),
            text: t('_insp_quote5'),
          }}
          image={images[`image5_${region}`].childImageSharp.gatsbyImageData}
        />
        <DashboardModuleBlock reverseElements />
      </ModuleSection2>

      {region === AppRegionEnum.Ru && (
        <Section bgColor="light">
          <Container>
            <VideoSection videoId="g4hHGjCXaxM" />
          </Container>
        </Section>
      )}

      {region === AppRegionEnum.Ru ? (
        <RequestDemo
          title={t('Trial period')}
          imageData={{
            image: images.demo_ru.childImageSharp.gatsbyImageData,
            title: `INSPECTION ${t('Demo')}`,
          }}
          appData={{
            text: t('Try SignalPhotoAgent App for free'),
            btnText: t('Request a demo_1'),
            onClick: () =>
              setModal({
                title: t('Request a demo of Signal'),
                requestType: 'Demo',
              }),
            btnColor: 'white',
          }}
          moduleData={{
            text: t('Inspection demo'),
            btnText: t('Download price list'),
            btnColor: 'transparent',
            onClick: () => toLink(downloadPriceListLink),
          }}
          buttonsElements={
            <Button
              size="large"
              color="transparent"
              onClick={() =>
                setModal({
                  title: t('Request testing'),
                  requestType: 'Trial',
                })
              }
            >
              {t('Request testing')}
            </Button>
          }
        />
      ) : (
        <RequestDemo
          title={t('Request a demo_1')}
          imageData={{
            image: images.demo_us.childImageSharp.gatsbyImageData,
            title: `INSPECTION ${t('Demo')}`,
          }}
          appData={{
            text: t('Try SignalPhotoAgent App for free'),
            btnText: t('Download'),
            btnColor: 'white',
            hasBtnIcon: true,
            onClick: () => setAppModal(true),
          }}
          moduleData={{
            text: t('Inspection demo'),
            btnText: t('Request a demo_1'),

            onClick: () =>
              setModal({
                title: t('Request a demo of Signal'),
                requestType: 'Demo',
              }),
            btnColor: 'transparent',
          }}
        />
      )}

      {modal && (
        <ContactForm
          onClose={() => setModal(undefined)}
          title={modal.title}
          requestType={modal.requestType}
        />
      )}
      {appModal && (
        <ModalDownloadApp
          title={t('Download Photo agent app')}
          appIosLink={inspIOSLinkAe}
          appAndroidLink={inspAndroidLinkAe}
          onClose={() => setAppModal(false)}
        />
      )}
      {region === AppRegionEnum.Ru && presentationModal && (
        <ModalDownloadFile
          fileUrl={signalMainDownloadPresentationLink}
          title={t('Download presentation')}
          image={images.presentatio_preview.childImageSharp.gatsbyImageData}
          imageTitle="Стандарт цифрового строительства"
          onClose={() => setPresentationModal(false)}
        />
      )}
    </>
  )
}

export default InspectionPage

const imagesQuery = graphql`
  query {
    banner_ru: file(relativePath: { eq: "inspection/signal-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    banner_us: file(relativePath: { eq: "inspection/signax-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    banner_mobile_ru: file(
      relativePath: { eq: "inspection/signal-banner-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    banner_mobile_us: file(
      relativePath: { eq: "inspection/signax-banner-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image1_ru: file(relativePath: { eq: "inspection/signal-image-1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image1_us: file(relativePath: { eq: "inspection/signax-image-1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image2_ru: file(relativePath: { eq: "inspection/signal-image-2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image2_us: file(relativePath: { eq: "inspection/signax-image-2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image3_ru: file(relativePath: { eq: "inspection/signal-image-3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image3_us: file(relativePath: { eq: "inspection/signax-image-3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image4_ru: file(relativePath: { eq: "inspection/signal-image-4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image4_us: file(relativePath: { eq: "inspection/signax-image-4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image5_ru: file(relativePath: { eq: "inspection/signal-image-5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    image5_us: file(relativePath: { eq: "inspection/signax-image-5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    demo_ru: file(relativePath: { eq: "inspection/signal-demo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    demo_us: file(relativePath: { eq: "inspection/signax-demo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 720, placeholder: BLURRED)
      }
    }
    presentatio_preview: file(
      relativePath: { eq: "signal-preview-presentation.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 552, placeholder: BLURRED)
      }
    }
  }
`
